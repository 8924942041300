<template>
  <main>
    <section class="section hero-section">
      <div class="hero-container">
        <div class="hero-left">
          <div class="hero-body">
            <h1 class="hero-body_title">
              Technology Consulting and Development for
              <TypingEffect :list="titleList" :speed="60" />
            </h1>
            <div class="hero-body_subtitle">
              Build your dedicated team and grow your business with AV DEVS
            </div>
          </div>
        </div>
        <div class="hero-right">
          <div class="hero-body">
            <h2 class="hero-body_title">
              The only technology partner you would ever need.
            </h2>
            <CustomButton class="secondary">
              <router-link to="/contact">Let's Talk</router-link>
            </CustomButton>
          </div>
        </div>
      </div>
    </section>
    <section class="section benefits-section">
      <div class="container">
        <div class="benefits-container">
          <div class="section-head">
            <Title>We address all your pain points</Title>
          </div>
          <div class="benefits-grid">
            <div
              class="grid-item"
              v-for="(item, i) in $store.state.siteData.key_benefits"
              :key="i"
            >
              <Card
                :icon="item.icon"
                :title="item.title"
                :text="item.text"
                :iconAlt="item.icon_alt"
                :theme="i % 2 ? 'dark' : 'light'"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <Vision />
    <Numbers />
    <!-- <section class="section articles-section">
      <div class="container">
        <div class="articles-container">
          <div class="section-head">
            <Subtitle>ARTICLES</Subtitle>
          </div>
          <div class="articles-grid">
            <article class="article">
              <div class="article-image">
                <img src="@/assets/images/article-dummy.jpg" alt="" />
              </div>
              <div class="article-body">
                <div class="article-body__card">
                  <div class="article-body__card__title">
                    It is a long established fact that a reader will be
                    distracted ipsum dolor.
                  </div>
                  <div class="article-body__card__date">29<br />nov</div>
                  <a
                    href="#"
                    class="article-body__card__link"
                    data-cursor-target
                    data-cursor-size="lg"
                  >
                    <span>know more</span>
                    <img src="@/assets/images/icon-arrow-right.svg" alt="" />
                  </a>
                </div>
              </div>
            </article>
            <article class="article">
              <div class="article-image">
                <img src="@/assets/images/article-dummy.jpg" alt="" />
              </div>
              <div class="article-body">
                <div class="article-body__card">
                  <div class="article-body__card__title">
                    It is a long established fact that a reader will be
                    distracted ipsum dolor.
                  </div>
                  <div class="article-body__card__date">29<br />nov</div>
                  <a
                    href="#"
                    class="article-body__card__link"
                    data-cursor-target
                    data-cursor-size="lg"
                  >
                    <span>know more</span>
                    <img src="@/assets/images/icon-arrow-right.svg" alt="" />
                  </a>
                </div>
              </div>
            </article>
          </div>
          <CustomButton class="primary">
            <router-link to="/blog">Click for more</router-link>
          </CustomButton>
        </div>
      </div>
    </section> -->
  </main>
</template>

<script>
import Vision from "@/components/layout/common/Vision.vue";
import Numbers from "@/components/layout/common/Numbers.vue";
import Card from "@/components/utils/Card.vue";
import CustomButton from "@/components/utils/CustomButton.vue";
import Title from "@/components/utils/Title.vue";
import TypingEffect from "@/components/utils/TypingEffect.vue";
import { mapActions } from "vuex";

export default {
  name: "HomePage",
  components: {
    Vision,
    Numbers,
    Card,
    CustomButton,
    Title,
    TypingEffect,
  },
  metaInfo: {
    title:
      "Technology Consulting and Engineering | Dedicated Developers - AV DEVS",
    meta: [
      {
        name: "description",
        content:
          "AV DEVS Specialize in custom web applications and mobile applications using dedicated development approach.",
      },
      {
        name: "og:title",
        content:
          "Technology Consulting and Engineering | Dedicated Developers - AV DEVS",
      },
      {
        name: "og:description",
        content:
          "AV DEVS Specialize in custom web applications and mobile applications using dedicated development approach.",
      },
    ],
  },
  data() {
    return {
      titleList: [
        "Start-ups",
        "Enterprises",
        "IT Companies",
        "Product Companies",
      ],
    };
  },
  methods: {
    ...mapActions(["fillTargets"]),
  },
  mounted() {
    this.fillTargets();
  },
};
</script>

<style lang="scss" scoped>
.hero-section {
  background: url("~@/assets/images/hero-bg.webp") center/cover;
  height: 750px;
  position: relative;

  .hero-container {
    display: flex;
    align-items: center;
    height: 100%;

    .hero-left,
    .hero-right {
      height: 100%;
      flex: 1;
      background-color: fade-out($primary-color, 0.6);
      position: relative;
      display: grid;
      grid-template-columns: repeat(13, 1fr);
      align-items: center;
    }

    .hero-right {
      background-color: fade-out($secondary-color, 0.6);

      .hero-body {
        &_title {
          margin-bottom: 2.5rem;
        }
      }
    }

    .hero-body {
      grid-column: 3 / 12;

      &_title {
        font-size: clamp(2rem, 3.5vw, 2.8rem);
        text-transform: uppercase;
        color: $white;
        font-weight: 800;
        margin-bottom: 2rem;
      }

      &_subtitle {
        width: 80%;
        font-size: 1.3rem;
        color: $white;
        font-weight: 400;
      }
    }
  }
}
.benefits-container {
  text-align: center;
  padding-bottom: 3.2rem;

  .title {
    color: $primary-color;
  }

  .benefits-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3vw;
  }
}

.articles-section {
  .articles-container {
    padding-bottom: 3.2rem;
    text-align: center;

    .articles-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 4rem;

      .article {
        transform-style: preserve-3d;
        &-image {
          box-shadow: 0 30px 40px rgb(226 230 233 / 25%);

          img {
            filter: brightness(0.9);
          }
        }

        &-body {
          &__card {
            box-shadow: 0 30px 40px rgb(226 230 233 / 25%);
            background-color: $white;
            text-align: left;
            width: 80%;
            margin: 0 auto;
            transform: translateY(-25%);
            padding: 2rem;
            position: relative;

            &__title {
              font-size: 1.25rem;
              font-weight: 600;
              line-height: 1.5;
              margin: 2.5rem 0 1rem;
            }

            &__date {
              background-color: $primary-color;
              position: absolute;
              top: -15%;
              right: 10%;
              font-size: 0.9rem;
              font-weight: 700;
              text-align: center;
              color: $white;
              text-transform: uppercase;
              line-height: 1.2;
              padding: 0.85rem;
            }

            &__link {
              span {
                font-size: 1rem;
                color: $primary-color;
                font-weight: 600;
                margin-right: 0.8rem;
              }

              img {
                display: inline-block;
                width: 1.25rem;
                transition: 500ms cubic-bezier(0.3, 1, 0.7, 1);
                transform: translateX(0);
              }

              &:hover {
                img {
                  transform: translateX(100%);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .articles-section {
    .articles-container {
      .articles-grid {
        .article {
          &-body {
            &__card {
              &__title {
                font-size: 1.125rem;
                margin: 1.5rem 0 1rem;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .benefits-container {
    .benefits-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .articles-section {
    .articles-container {
      .articles-grid {
        .article {
          &-body {
            &__card {
              width: 100%;
              padding: 2rem 1.5rem;

              &__title {
                font-size: 1rem;
              }

              &__date {
                font-size: 0.875rem;
                padding: 0.75rem;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .hero-section {
    .hero-container {
      flex-direction: column;

      .hero-left,
      .hero-right {
        height: 50%;
      }

      .hero-body {
        grid-column: 2 / 13;
        text-align: center;

        &_title {
          font-size: clamp(2rem, 5vw, 2.8rem);
        }

        &_subtitle {
          width: 100%;
          font-size: 1rem;
        }
      }
    }
  }

  .articles-section {
    .articles-container {
      .articles-grid {
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;

        .article {
          &-body {
            &__card {
              padding: 2rem;
              &__title {
                font-size: 1.25rem;
                margin-top: 2rem;
              }

              &__date {
                font-size: 0.9rem;
                padding: 0.85rem;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .benefits-container {
    .benefits-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .articles-section {
    .articles-container {
      .articles-grid {
        .article {
          &-body {
            &__card {
              padding: 1.875rem 1rem;
              &__title {
                font-size: 1rem;
                margin-top: 2rem;
              }

              &__date {
                font-size: 0.9rem;
                padding: 0.85rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
