<script>
export default {
    name: 'TypingEffect',
    props: {
        list: {
            type: Array,
            required: true,
        },
        speed: {
            type: Number,
        },
    },
    data() {
        return {
            item: '',
            displayText: '',
            isDeleting: false,
            runner: null,
            count: 0,
        };
    },
    mounted() {
        this.autoTyping();
    },
    methods: {
        delayFn(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },

        async resetTyping(delay) {
            clearInterval(this.runner);
            await this.delayFn(delay);
            this.isDeleting = !this.isDeleting;
        },
        autoTyping() {
            if (!this.isDeleting) {
                // reset
                this.displayText = '';
                this.item = this.list[this.count];
            }

            this.runner = setInterval(
                async () => {
                    // deleting
                    if (this.isDeleting) {
                        this.displayText = this.displayText.slice(0, -1);
                        if (!this.displayText.length) {
                            if (this.count == this.list.length - 1) {
                                this.count = 0;
                            } else {
                                this.count = this.count + 1;
                            }
                            await this.resetTyping(500);
                            this.autoTyping();
                        }
                        return;
                    }
                    // typing
                    const idx = this.displayText.length;
                    this.displayText += this.item[idx];
                    if (this.displayText === this.item) {
                        await this.resetTyping(1200);
                        this.autoTyping();
                    }
                },
                this.speed ? this.speed : 120
            );
        },
    },
};
</script>

<template>
    <div class="typing">
        <span>{{ displayText }}</span>
        <span class="caret"></span>
    </div>
</template>

<style lang="scss" scoped>
.caret {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: clamp(2rem, 3.5vw, 2.5rem);
    margin-top: -0.5rem;
    margin-left: 0.25rem;
    border-right: 4px solid $white;
    animation: caret-flash 0.8s steps(1) infinite;
    animation-timing-function: ease;
}

@keyframes caret-flash {
    50% {
        opacity: 0;
    }
}
</style>

