<template>
  <section class="section vision-section">
    <div class="container">
      <div class="vision-container">
        <div class="vision-title">
          <div>Our Vision</div>
        </div>
        <div class="vision-text">
          <p>
            {{ $store.state.siteData.vision }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Vision",
};
</script>

<style lang="scss" scoped>
.section {
  background: $primary-color;
  position: relative;

  &::after {
    content: "";
    background: url("~@/assets/images/vision-bg.webp") center/cover;
    mix-blend-mode: multiply;
    position: absolute;
    inset: 0;
  }

  .vision-container {
    position: relative;
    height: 260px;
    display: grid;
    grid-template-columns: repeat(13, 1fr);
    align-items: center;

    .vision-title {
      grid-column: 2 / 5;

      div {
        color: $white;
        font-size: clamp(2rem, 5vw, 2.5rem);
        font-weight: 800;
        line-height: 1.5;
      }
    }

    .vision-text {
      grid-column: 5 / 11;

      p {
        color: $white;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.5;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .vision-section {
    .vision-container {
      .vision-text {
        grid-column: 5 / 13;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .vision-section {
    .vision-container {
      grid-template-rows: repeat(7, 1fr);
      height: 250px;

      .vision-title {
        grid-column: 2 / 13;
        grid-row: 3;
        text-align: center;
      }

      .vision-text {
        grid-column: 1 / 14;
        grid-row: 5;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .vision-section {
    .vision-container {
      .vision-text {
        p {
          font-size: 1.1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .vision-section {
    .vision-container {
      .vision-text {
        p {
          font-size: 1rem;
        }
      }
    }
  }
}
</style>
